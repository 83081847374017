<template>
  <div class="bg-box">
    <div class="cent-box">
      <div class="logo-box">
        <img src="../assets/logo.png" alt="" class="log-img" />
      </div>
      <div class="company-item">
        <div class="item-box1" @click="storeBtn()">
          <div class="item-box-top">
            <img src="../assets/sc.png" alt="" class="icone" />
            <p>绿色商城</p>
          </div>
          <div class="item-box-bott">
            <p>进入平台</p>
            <img src="../assets/jt (1).png" alt="" />
          </div>
        </div>
        <div class="item-box2" @click="logisticsBtn()">
          <div class="item-box-top">
            <img src="../assets/fj.png" alt="" class="icone" />
            <p>国际物流</p>
          </div>
          <div class="item-box-bott">
            <p>进入平台</p>
            <img src="../assets/jt (3).png" alt="" />
          </div>
        </div>
        <div class="item-box3" @click="logtzhBtn()">
          <div class="item-box-top" >
            <img src="../assets/co2.png" alt="" class="icone" />
            <p>碳综合</p>
          </div>
          <div class="item-box-bott">
            <p>进入平台</p>
            <img src="../assets/jt (2).png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <p>Copyright 2023 侨链国际 版权所有</p>
      <a class="coterntru" href="http://beian.miit.gov.cn/" target="_blank"
        >浙ICP备2020044037号-1
      </a>
      <p>浙公网安备33021202001524号</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    storeBtn() {
      window.open('https://mall.ceecexpo.com/', '_blank');
    },
    logisticsBtn() {
      window.open('https://logistics.ceecexpo.com/', '_blank');
    },
    logtzhBtn() {
      window.open('https://carbon.ceecexpo.com/', '_blank');
    },
    
  },
};
</script>

<style scoped lang="scss">
@keyframes shineLight {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4); /* 添加开始阴影并设置透明度 */
  }
  100% {
    box-shadow: 0 0 0 22px rgba(255, 255, 255, 0); /* 添加结束阴影并设置透明度 */
  }
}
.bg-box {
  height: 100%;
  height: 100vh;
  background-color: #0969bc;
  background-image: url("../assets/bg-home.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  z-index: -100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cent-box {
  width: 70%;
  height: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // border: 1px solid red;

  .logo-box {
    width: 522px;
    height: 147px;
    // border: 1px solid red;

    .log-img {
      width: 522px;
      height: 147px;
    }
  }

  .company-item {
    width: 71%;
    height: 320px;
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;

    .item-box1 {
      width: 254px;
      height: 320px;
      // border: 1px solid red;
      cursor: pointer;
      .item-box-top {
        width: 254px;
        height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(0deg, #c9971b 0%, #f3b82c 100%);

        .icone {
          width: 66px;
          height: 70px;
        }

        p {
          font-size: 30px;
          color: #fff;
        }
      }

      .item-box-bott {
        width: 254px;
        height: 60px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #f3b82c;
          font-size: 17px;
        }
        img {
          width: 10px;
          height: 17px;
          margin-left: 7px;
        }
      }
    }

    .item-box2 {
      width: 254px;
      height: 320px;
      // border: 1px solid red;
      cursor: pointer;
      .item-box-top {
        width: 254px;
        height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(0deg, #fe5a58 0%, #ff625e 100%);

        .icone {
          width: 66px;
          height: 70px;
        }

        p {
          font-size: 30px;
          color: #fff;
        }
      }

      .item-box-bott {
        width: 254px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        p {
          color: #ff625e;
          font-size: 17px;
        }
        img {
          width: 10px;
          height: 17px;
          margin-left: 7px;
        }
      }
    }

    .item-box3 {
      width: 254px;
      height: 320px;
      cursor: pointer;
      .item-box-top {
        width: 254px;
        height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(0deg, #09b724 0%, #33cf4b 100%);

        .icone {
          width: 66px;
          height: 70px;
        }
        p {
          font-size: 30px;
          color: #fff;
        }
      }

      .item-box-bott {
        width: 254px;
        height: 60px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #33cf4b;
          font-size: 17px;
        }
        img {
          width: 10px;
          height: 17px;
          margin-left: 7px;
        }
      }
    }
  }
  .item-box1:hover {
    animation: shineLight 1.5s infinite;
  }
  .item-box2:hover {
    animation: shineLight 1.5s infinite;
  }
  .item-box3:hover {
    animation: shineLight 1.5s infinite;
  }
}
.footer-box {
  height: 30px;
  width: 100%;
  background: #f8fafd;
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  p {
    color: #6c7d8f;
    font-size: 14px;
    margin-left: 20px;
    z-index: 1000;
  }
  .coterntru {
    margin-left: 20px;
    color: #6c7d8f;
    font-size: 14px;
    text-decoration:none
    // margin-top: -10px;
  }
}
</style>
