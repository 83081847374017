var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-box"},[_c('div',{staticClass:"cent-box"},[_vm._m(0),_c('div',{staticClass:"company-item"},[_c('div',{staticClass:"item-box1",on:{"click":function($event){return _vm.storeBtn()}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"item-box2",on:{"click":function($event){return _vm.logisticsBtn()}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"item-box3",on:{"click":function($event){return _vm.logtzhBtn()}}},[_vm._m(5),_vm._m(6)])])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-box"},[_c('img',{staticClass:"log-img",attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-top"},[_c('img',{staticClass:"icone",attrs:{"src":require("../assets/sc.png"),"alt":""}}),_c('p',[_vm._v("绿色商城")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-bott"},[_c('p',[_vm._v("进入平台")]),_c('img',{attrs:{"src":require("../assets/jt (1).png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-top"},[_c('img',{staticClass:"icone",attrs:{"src":require("../assets/fj.png"),"alt":""}}),_c('p',[_vm._v("国际物流")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-bott"},[_c('p',[_vm._v("进入平台")]),_c('img',{attrs:{"src":require("../assets/jt (3).png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-top"},[_c('img',{staticClass:"icone",attrs:{"src":require("../assets/co2.png"),"alt":""}}),_c('p',[_vm._v("碳综合")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-bott"},[_c('p',[_vm._v("进入平台")]),_c('img',{attrs:{"src":require("../assets/jt (2).png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-box"},[_c('p',[_vm._v("Copyright 2023 侨链国际 版权所有")]),_c('a',{staticClass:"coterntru",attrs:{"href":"http://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("浙ICP备2020044037号-1 ")]),_c('p',[_vm._v("浙公网安备33021202001524号")])])
}]

export { render, staticRenderFns }